import React from "react"

const rounded = {
    borderRadius: "50%",
    height: "128px",
    width: "128px",
}

export default function Avatar() {
    return (
        <div>
            <figure className="is-128x128 right-align">
                <img style={rounded} src="matthew_gladman_profile_2_scaled.jpg" alt="Matthew Gladman Avatar" height="128" width="128" />
            </figure>
        </div>
    )
}
