import Project from "../Project"
import React from "react"

export default function Projects() {
    return (
        <>
            <h3 className="is-size-3">Top 3 Interesting Projects</h3>
            <Project projectName="Realtime Retrospective Board" projectType="Personal" projectLink="https://never.io/retrospective/">
                <p>
                    This project is a small weekend project that was born out of a need of having a fast and polished retrospective software that we could spin
                    up a new board without having any admin or sign ups required (we performed retrospectives with non-technical individuals as well as
                    technical).
                </p>
                <p>&nbsp;</p>
                <p>
                    All the other retrospective software I could find either required sign up (or wasn't free), was clunky or wasn't even tailored for
                    retrospectives (ie, Trello). This was born out of a real need to be able to have several users colaborate during a retrospective.
                </p>
                {/*<p>&nbsp;</p>
                <p>
                    (Feel free to use this for yourself! It's best to test it
                    out with two windows or two users to show the real time
                    effect)
                </p>*/}
                <p>&nbsp;</p>
                <p>
                    <strong>Notable technology used: </strong>
                    React, Bulma, TypeScript, Firebase Realtime Database
                </p>
            </Project>
            <Project
                projectName="Accident Allocation System for Department of Transport Victoria"
                projectType="Professional"
                projectLink="https://transport.vic.gov.au/"
            >
                <p>
                    The Accident Allocation System we built for the Department of Transport Victoria was an intense marathon, we built the entire system from
                    scratch in 3 months on top of Salesforce.
                </p>
                <p>&nbsp;</p>
                <p>
                    This includes integration with the telephony system, rapid finding of where an accident has taken place over the phone, an Allocation Engine
                    to equitably distribute jobs and find the best depot for a particular accident, adding in GeoSpatial indexing into Salesforce (which is not
                    natively supported), accident and depot management, integration with STREAMS via sFTP, integration with Melways including their tiling
                    system, integration with Google Maps, reporting, dashboards and more.
                </p>
                <p>&nbsp;</p>
                <p>
                    <strong>Notable technology used: </strong>
                    Salesforce, Apex, Lightning Web Component, Salesforce Lightning Design System (SLDS), GeoJSON, Leaflet, AWS Lambda, Amazon DynamoDB, AWS
                    AppSync, AWS EventBridge, Amazon VPC, sFTP, Melways, Google, TypeScript, JavaScript, NodeJS, ESBuild and Jest
                </p>
            </Project>
            {/*<Project
                projectName="COVID-19 Chatbot for business.gov.au"
                projectType="Professional"
                projectLink="https://business.gov.au/Risk-management/Emergency-management/Coronavirus-information-and-support-for-business"
            >
                <p>
                    This is a custom chatbot built using Salesforce Einstein for business.gov.au response for COVID-19 (there is a plan to rollout the bot for a
                    broader set of content, but the content is still being written for it by the government)
                </p>
                <p>&nbsp;</p>
                <p>This chatbot is publicly available and free for all users in Australia to use</p>
                <p>&nbsp;</p>
                <p>
                    <strong>Notable technology used: </strong>
                    Lightning Web Components, <a href="https://ausgov.github.io/bga-design-system/index.html">BGA Design System</a>, Salesforce Einstein AI and
                    Salesforce Einstein Bots
                </p>
            </Project>*/}
            <Project projectName="EmailFlow" projectType="Professional" projectLink="https://emailflow.app">
                <p>
                    EmailFlow is a new addon for Outlook within Office 365 to help users review, approve and collaborate on emails before sending them out. The
                    primary focus is to take a traditionally long, arbitrary and frustrating experience; and turn it into something quick and pleasant. We are
                    introduction additional powerful features including the ability for multiple authors to collaborate on a single email, track the changes of
                    an email over time and more!
                </p>
                <p>&nbsp;</p>
                <p>The Minimum Delightful Product (aka MVP) has been launched and we are continuing marketing efforts to bring it to market.</p>
                <p>&nbsp;</p>
                <p>
                    <strong>Notable technology used: </strong>
                    AWS Lambda, Serverless Framework, API Gateway, Cloudfront, S3, DynamoDB, SES, Buddy CI, React, TypeScript, Lerna, Microsoft Graph API, Node
                    and Fabric UI
                </p>
            </Project>
        </>
    )
}
