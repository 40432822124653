import React from "react"

export default function Project(props) {
    const projectName = props?.projectName ?? ""
    const projectLink = props?.projectLink ?? ""
    const projectType = props?.projectType ?? ""

    const title = projectLink ? (
        <a href={projectLink} target="_blank">
            {projectName}
        </a>
    ) : (
        projectName
    )

    const colorStyle = { borderLeftColor: props?.color ?? "" }
    return (
        <div className="work-history-item" style={colorStyle}>
            <h3 className="is-size-4 is-underlined">{title}</h3>
            <p className="is-grey-light">{projectType}</p>
            <p>&nbsp;</p>
            {props.children}
        </div>
    )
}
