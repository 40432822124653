import React from "react"
import Education from "./sections/Education"
import Technologies from "./sections/Technologies"
import Contact from "./sections/Contact"

export default function Sidebar() {
    return (
        <>
            <Contact />
            <Technologies />
            <Education />
        </>
    )
}
