import React from "react"
import Avatar from "./Avatar"

export default function Header() {
    return (
        <section className="hero is-bold purple-rainbow">
            <div className="hero-body">
                <div className="container">
                    <div className="columns is-vcentered">
                        <div className="column">
                            <div className="glass">
                                <h1 className="title">Matthew Gladman</h1>
                                <h2 className="subtitle">Technology Leader / Software Engineer / DevOps</h2>
                            </div>
                        </div>
                        <div className="column">
                            <Avatar />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
