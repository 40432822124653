import React from "react"
import "../universal.scss"
import Header from "../components/Header"
import Sidebar from "../components/Sidebar"
import MainContent from "../components/MainContent"
import { Helmet } from "react-helmet"

export default function Home() {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Matthew Gladman | Resume</title>
                <link rel="canonical" href="https://resume.never.io" />
            </Helmet>
            <Header />
            <div className="main-content">
                <div className="container">
                    <div className="columns">
                        <div className="column">
                            <MainContent />
                        </div>
                        <div className="column is-one-third">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
