import React from "react"

export default function WhoIAm() {
    return (
        <>
            <h3 className="is-size-3">About Me</h3>

            <p>I love making delightful software with a team of brilliant people.</p>
            <p>&nbsp;</p>
            <p>
                As a Technology Leader, my role encompasses more than just leading people and writing code. I've worn the hat of Project Manager, Pre-Sales
                Engineer, UI / UX Design, Graphic Design, Sound Design, Business Analysis, Consultant, IT and Mentor.
            </p>
            <p>&nbsp;</p>
            <p>
                I've been creating software since primary school and haven't really stopped. In my professional life, I've created several projects I'm quite
                proud of such as making the Department of Transport Victoria's Accident Management system, launching a brand new business by using some
                experimental SEO techniques and created a helpdesk platform installed on more than 30 thousand PCs throughout Australia for our PC support
                customers.
            </p>
            {/*<p>&nbsp;</p>

            <p>My name is Matthew Gladman. I'm a technology leader, my main focuses are people, software engineering and DevOps.</p>

            <p>&nbsp;</p>

            <p>
                I've been working at getNEXT for about 2 years and leading the businesses technology and business decisions. We have a small but talented
                software team focused on creating new products internally as well as consulting for our existing clients.
            </p>

            <p>
                My primary functions at getNEXT are hands-on development work, planning software direction and architecture, consulting with clients, shaping
                our sales and marketing efforts, maintaining infrastructure (primarily AWS), Salesforce consulting and development, scrum master and DevOps
                engineering practices within the organisation and for our clients.
            </p>

            <p>&nbsp;</p>

            <p>
                In my spare time, I love to work on small software side-projects, hang out with friends either in person or online (lockdown is limiting this to
                just online), play video games, watch TV / anime and spend time with my amazing wife.
            </p>*/}
        </>
    )
}
