import React from "react"
import Section from "../Section"

export default function Education() {
    return (
        <Section title="Education" color="#87efff">
            <p>
                <strong>Swinburne University of Technology</strong>
            </p>
            <p>Bachelor of Multimedia (Games and Interactivity) / Bachelor of Science (Computer Science and Software Engineering)</p>
            <p className="is-grey-light">2010 – 2013 (incomplete)</p>
            <p>&nbsp;</p>
            <p>
                <strong>Salesforce</strong>
            </p>
            <p>Certified Salesforce Developer</p>
        </Section>
    )
}
