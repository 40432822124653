import React from "react"

export default function WorkHistoryItem(props) {
    const jobName = props?.jobName ?? ""
    const jobTitle = props?.jobTitle ?? ""
    const length = props?.length ?? ""
    const location = props?.location ?? ""
    const imageLink = props?.imageLink ?? ""

    const WorkHistoryImage = () => {
        return imageLink ? (
            <>
                <img src={imageLink} className="va-middle" style={{ height: "72px" }} alt={jobName} />
            </>
        ) : (
            <></>
        )
    }

    const colorStyle = {}
    //const colorStyle = { borderLeftColor: props?.color ?? "" }
    return (
        <div className="work-history-item" style={colorStyle}>
            <WorkHistoryImage /> <br />
            <h3 className="is-size-4 is-underlined va-middle">
                {jobTitle} @ {jobName}
            </h3>
            <p className="is-grey-light">
                {length} at {location}
            </p>
            <p>&nbsp;</p>
            {props.children}
        </div>
    )
}
