import React from "react"
import WhoIAm from "./content/WhoIAm"
import WorkHistory from "./content/WorkHistory"
import Projects from "./content/Projects"

export default function MainContent() {
    return (
        <div className="box">
            <WhoIAm />

            <hr />

            <WorkHistory />

            <hr />

            <Projects />
        </div>
    )
}
