import React from "react"

export default function Icon({ iconFile, title }) {
    const width = 50
    const style = { maxWidth: `${width}px`, width: `${width}px`, display: "inline-block", verticalAlign: "middle", paddingRight: "10px" }
    return (
        <>
            <img alt={title} src={`icons/${iconFile}`} title={title} style={style} />
        </>
    )
}
