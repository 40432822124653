import WorkHistoryItem from "../WorkHistoryItem"
import React from "react"

export default function WorkHistory() {
    return (
        <>
            <h3 className="is-size-3">Work History</h3>
            <div>
                <WorkHistoryItem
                    jobName="getNEXT"
                    jobTitle="Chief Technology Officer"
                    length="July 2018 - Present"
                    location="St Kilda, Melbourne"
                    imageLink="https://getnext.com.au/wp-content/uploads/2018/08/getnext-logo-e1534730029765.png"
                    color="#87ffb7"
                >
                    <p>
                        getNEXT is an Australian-based technology consulting company specialising in enriching the value of technology, content and
                        communication.
                    </p>

                    <p>&nbsp;</p>

                    <p>
                        My primary functions at getNEXT are hands-on development work, planning software direction and architecture, consulting with clients,
                        shaping our sales and marketing efforts, maintaining infrastructure (primarily AWS), Salesforce consulting and development, scrum master
                        and DevOps engineering practices within the organisation and for our clients.
                    </p>

                    <p>&nbsp;</p>

                    <p>
                        We also have a range of software products that we acquired in the USA focusing around{" "}
                        <a href="https://truedit.com/" target="_blank">
                            print and design
                        </a>{" "}
                        which I also maintain, develop and support - including InDesign Plug-Ins.
                    </p>

                    <p>&nbsp;</p>

                    <p>
                        Our current focus is launching our own products in the Salesforce and Microsoft marketplaces and shifting our attention away from the
                        consulting / agency side of the business.
                    </p>

                    <p>&nbsp;</p>

                    <p>
                        <strong>Recognisable clients I worked with directly: </strong>
                        <a href="https://transport.vic.gov.au/" target="_blank">
                            Department of Transport Victoria
                        </a>
                        ,{" "}
                        <a href="https://business.gov.au/Risk-management/Emergency-management/Coronavirus-information-and-support-for-business" target="_blank">
                            Business Government Australia
                        </a>
                        ,{" "}
                        <a href="https://exclusives.webjet.com.au" target="_blank">
                            Webjet Exclusives
                        </a>{" "}
                        (currently paused due to COVID-19),{" "}
                        <a href="https://sandiegozoowildlifealliance.org/" target="_blank">
                            San Diego Zoo Wildlife Alliance (USA)
                        </a>
                        ,{" "}
                        <a href="https://www.euromoneyplc.com/home-page" target="_blank">
                            Euromoney PLC (USA)
                        </a>
                        ,{" "}
                        <a href="https://www.probegroup.com.au/" target="_blank">
                            Probe Group
                        </a>
                        ,{" "}
                        <a href="https://www.careabout.com.au" target="_blank">
                            Care About
                        </a>
                        ,{" "}
                        <a href="https://www.advantagepharmacy.com.au/" target="_blank">
                            Advantage Pharmacy
                        </a>
                        ,{" "}
                        <a href="https://www.consolidated.com.au" target="_blank">
                            Consolidated Property Services
                        </a>
                        ,{" "}
                        <a href="https://m3property.com.au" target="_blank">
                            m3property
                        </a>{" "}
                        and{" "}
                        <a href="https://www.mailguard.com.au" target="_blank">
                            MailGuard
                        </a>
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        <strong>Notable technology I worked with: </strong>
                        Docker, AWS Lambda, Serverless Framework, Ansible, Chef, PHP, Laravel, Go, ES2019+ / JavaScript, TypeScript, React, Jest, Lightning Web
                        Components, Salesforce, Apex, AWS CodeDeploy, MySQL, DynamoDB, Amazon Lex, Einstein AI, Amazon CloudFront / S3, AWS AppSync / AWS
                        EventBridge, Amazon ECS and more.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        <strong>Integrations I've built or worked with: </strong>
                        Salesforce, Xero, MYOB, ConnectWise Manage, Melways, Google Maps, Stripe, SharePoint, Azure AD, Jira, Harvest, Zendesk, Twilio, Magento,
                        Shopify, WooCommerce, Pardot, TruEdit, MailChimp, HubSpot, ABM, Page Proof, WordPress, M-Files and more.
                    </p>
                </WorkHistoryItem>

                <WorkHistoryItem
                    jobName="Agyle Labs"
                    jobTitle="Chief Technology Officer"
                    length="November 2013 - November 2016"
                    location="Kensington, Melbourne"
                    color="#ff87a1"
                    imageLink="agyle-time-header-logo_transparent.png"
                >
                    <p>
                        Agyle Labs is a startup that was founded within QK Services, our only product was{" "}
                        <a href="https://agyletime.com" target="_blank">
                            Agyle Time
                        </a>
                        , a modern web based Workforce Management app that focused on call centre operations, this includes; forecasting call workload,
                        rostering staff, time-sheets, real time reporting, assigning tasks for agents, schedule optimisation and more. Agyle Labs / Agyle Time
                        has been acquired by{" "}
                        <a href="https://www.playvox.com/products/workforce-management/" target="_blank">
                            Playvox
                        </a>{" "}
                        in 2020.
                    </p>

                    <p>&nbsp;</p>

                    <p>
                        <strong>Notable technology I worked with: </strong>
                        Drone CI, PHP, Laravel, MySQL, Amazon EC2, AWS Elastic Beanstalk, Golang, Knockout.js, Jasmine, Bootstrap 3, Salesforce, Xero API,
                        Asterisk, REST APIs and MySQL
                    </p>
                </WorkHistoryItem>

                <WorkHistoryItem
                    jobName="QK Services"
                    jobTitle="Senior Software Engineer"
                    length="October 2010 - July 2018"
                    location="Kensington, Melbourne"
                    color="#87efff"
                    imageLink="qk-logo-dark.png"
                >
                    <p>QK Services got acquired by getNEXT / Cloudstratagem.</p>
                    <p>
                        Originally QK Services was primarily a call centre operation focusing on technology working with some of Australia's biggest names such
                        as Optus, Dicksmith, JB Hi-Fi and our own brands.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        I primarily focused on creating tools and platforms to create a smooth operation, this includes developing a world class support and
                        call centre software leveraging Salesforce and Asterisk, platforms to manage technicians and their work load (WFM), creating an interval
                        based payment platform on Salesforce and helpdesk software installed on tens of thousands of computers.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        <strong>Notable technology I worked with: </strong>
                        Chef, Salesforce, Apex, Elastic Beanstalk, PHP, Laravel, AngularJS, .Net (C# & Basic), ConnectWise Screen, Asterisk and the SIP Protocol
                    </p>
                </WorkHistoryItem>
            </div>
        </>
    )
}
