import React from "react"
import Section from "../Section"
import Icon from "../Icon"

export default function Technologies() {
    return (
        <Section title="Technologies I Love" color="#8789ff">
            <div className="columns is-multiline has-text-weight-bold">
                <div className="column is-half">
                    <Icon iconFile="ts.svg" title="TypeScript" /> TypeScript
                </div>

                <div className="column is-half">
                    <Icon iconFile="js.svg" title="JavaScript" /> JavaScript
                </div>

                <div className="column is-half middle-align">
                    <Icon iconFile="noun-css-1231393.svg" title="CSS" /> CSS
                </div>

                <div className="column is-half middle-align">
                    <Icon iconFile="noun-react-2998697.svg" title="React" /> React
                </div>

                <div className="column is-half middle-align">
                    <Icon iconFile="noun-docker-4112448.svg" title="Docker" /> Docker
                </div>

                <div className="column is-half middle-align">
                    <Icon iconFile="aws.svg" title="AWS" /> AWS
                </div>

                <div className="column is-half middle-align">
                    <Icon iconFile="aws-lambda.svg" title="AWS Lambda" /> Lambda
                </div>

                <div className="column is-half middle-align">
                    <Icon iconFile="dynamodb.svg" title="DynamoDB" /> DynamoDB
                </div>

                <div className="column is-half middle-align">
                    <Icon iconFile="salesforce.svg" title="Salesforce" /> Salesforce
                </div>

                <div className="column is-half middle-align">
                    <Icon iconFile="http.svg" title="APIs / REST" /> APIs / REST
                </div>

                <div className="column is-half middle-align">
                    <Icon iconFile="php.svg" title="PHP" /> PHP
                </div>

                <div className="column is-half middle-align">
                    <Icon iconFile="laravel.svg" title="Laravel" /> Laravel
                </div>

                <div className="column is-half middle-align">
                    <Icon iconFile="database.svg" title="MySQL" /> MySQL
                </div>

                <div className="column is-half middle-align">
                    <Icon iconFile="buddy.svg" title="Buddy (CI / CD)" /> Buddy (CI / CD)
                </div>

                <div className="column is-half middle-align">
                    <Icon iconFile="terminal.svg" title="Linux" /> GNU/Linux
                </div>

                <div className="column is-half middle-align" style={{ verticalAlign: "middle" }}>
                    ... and more! <span style={{ color: "#F08FA1" }}>❤</span>
                </div>
            </div>
        </Section>
    )
}
