import React from "react"
import Section from "../Section"

export default function Contact() {
    return (
        <Section title="Contact" color="#cb87ff">
            <div className="content">
                <p>Living in Melbourne</p>
                <p>The best way to contact me is via email or SMS</p>
                <ul>
                    <li>
                        <a href="tel:0466621277">0466621277</a>
                    </li>
                    <li>
                        <a href="mailto:matt@never.io">matt@never.io</a>
                    </li>
                </ul>
            </div>
        </Section>
    )
}
