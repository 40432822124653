import React from "react"

export default function Section(props) {
    const headerColor = {}

    if (props.color) {
        headerColor.backgroundColor = props.color
    }

    return (
        <div className="box-container">
            <div className="box-header" style={headerColor}>
                <h4 className="is-size-4">{props?.title ?? "Section"}</h4>
            </div>
            <div className="box-body">{props.children}</div>
        </div>
    )
}
